import { graphql } from 'gatsby'
import Container from '../containers/events'

export default Container

export const pageQuery = graphql`
  query PostsEventQuery($paginatePostsPage: Boolean!, $skip: Int, $limit: Int) {
    paginated: allEvent(
      filter: { private: { ne: true } }
      sort: { fields: [eventdate], order: DESC }
      limit: $limit
      skip: $skip
    ) @include(if: $paginatePostsPage) {
      nodes {
        id
        title
        slug
        link
        excerpt
        timeToRead
        featured
        eventdate(formatString: "MMMM DD, YYYY")
        category {
          ...BlogCategory
        }

        featuredImage {
          __typename
          ... on ImageSharp {
            ImageSharp_vertical: gatsbyImageData(
              width: 900
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_horizontal: gatsbyImageData(
              width: 807
              height: 400
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
            ImageSharp_hero: gatsbyImageData(
              width: 1600
              height: 650
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
          ... on ContentfulAsset {
            ContentfulAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              cropFocus: CENTER
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
            ContentfulAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              formats: [JPG, WEBP]
              resizingBehavior: THUMB
            )
          }
          ... on SanityImageAsset {
            SanityImageAsset_vertical: gatsbyImageData(
              width: 360
              height: 470
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_horizontal: gatsbyImageData(
              width: 807
              height: 400
              outputPixelDensities: [0.5, 1]
            )
            SanityImageAsset_hero: gatsbyImageData(
              width: 1600
              height: 650
              outputPixelDensities: [0.5, 1]
            )
          }
        }
      }

      pageInfo {
        pageCount
        hasNextPage
        currentPage
        hasPreviousPage
      }
      totalCount
    }
  }
`
