import React, { useState, useEffect, useRef } from 'react'
import Seo from '@widgets/Seo'

import { BsCalendar3 } from 'react-icons/bs'

import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Layout, Stack, Main, PreFooter, Hero } from '@layout'
import {
  attachExcerptToEvent,
  attachCategoriesToEvent,
  getStaticPageSeoDetails
} from '@utils-blog'
import Divider from '@components/Divider'
import { Heading, Box } from 'theme-ui'
import HeroComponent from '../../../../themes/gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'

const Input = ({
  onChange,
  placeholder,
  value,
  onClick,
  isSecure,
  id,
  onOpenCalendar
}) => {
  return (
    <div style={styles.inputWrapper}>
      <BsCalendar3 style={styles.cursor} onClick={onOpenCalendar} />
      <input
        onClick={onOpenCalendar}
        style={styles.customInput}
        onChange={onChange}
        value={value}
        id={id}
        placeholder={placeholder}
      />
    </div>
  )
}

const styles = {
  customInput: {
    border: 'none',
    padding: '9px',
    width: '100%',
    outline: 'none',
    background: 'transparent'
  },
  inputWrapper: {
    border: '1px solid #2f3747',
    padding: '6px',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    borderRadius: '5px'
  },
  cursor: {
    cursor: 'pointer'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  pr: {
    pr:20
  }
}
const Event = props => {
  const events = props.data.paginated
  const [startDate, setStartDate] = useState()
  const [openCalendar, setOpenCalendar] = useState(false)
  const [item, setItem] = useState({})
  const [endDate, setEndDate] = useState()
  attachCategoriesToEvent(events)
  events.nodes.forEach(ele => {
    ele.category = ele.categories[0].name
    delete ele.categories
  })

  let seoData = getStaticPageSeoDetails('shared.events')

  const eventsSeoData = {
    ...seoData,
    Events: {
      ...seoData?.Events,
      keywords: seoData?.Events?.keywords?.split('\n')
    }
  }

  attachExcerptToEvent(events)
  let list = groupBy([...events.nodes], c => c.category)

  useEffect(() => {
    const last = events.nodes.length - 1

    setStartDate(new Date(events.nodes[last].eventdate))
    setEndDate(new Date(events.nodes[0].eventdate))
    filterDate(
      new Date(events.nodes[last].eventdate),
      new Date(events.nodes[0].eventdate)
    )
  }, [])

  const onChange = dates => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    if (end) {
      setOpenCalendar(false)
    }
    filterDate(start, end ? end : new Date())
  }

  const filterDate = (start, end) => {
    list = groupBy([...events.nodes], c => c.category)
    Object.keys(list).forEach(ele => {
      list[ele] = list[ele].filter(event => {
        return (
          new Date(event.eventdate).getTime() >= start.getTime() &&
          new Date(event.eventdate).getTime() <= end.getTime()
        )
      })
    })
    setItem({})
    setItem({ ...list })
  }

  function groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    )
  }
  const onOpenCalendar = () => {
    setOpenCalendar(true)
  }
  const closeCalendar = () => {
    setOpenCalendar(false)
  }

  return (
    <Layout {...props}>
      <Seo
        title={eventsSeoData?.Events?.metaTitle}
        description={eventsSeoData?.Events?.metaDescription}
        keywords={eventsSeoData?.Events?.keywords}
        thumbnail={eventsSeoData?.Events?.metaImage}

      />
      <Hero full>
        <Hero style={{ zIndex: 1 }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'event'}
            {...props}
            callFrom='Pope Francis and Francis'
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />

      <Divider />
      {Object.keys(item).map((key, index) => {
        return (
          <Stack>
            <Main>
              {index === 0 && (
                <div style={styles.wrapper}>
                  <Heading variant='h1' sx={styles.pr}>Events</Heading>
                  <Box>
                    <DatePicker
                      selectsRange
                      dateFormat='MMMM dd yyyy'
                      placeholderText=' Choose Date...'
                      startDate={startDate}
                      onClickOutside={closeCalendar}
                      open={openCalendar}
                      shouldCloseOnSelect={true}
                      endDate={endDate}
                      customInput={<Input onOpenCalendar={onOpenCalendar} />}
                      onChange={onChange}
                    />
                    <Divider />
                  </Box>
                </div>
              )}
              <CardList
                title={key}
                nodes={item[key]}
                variant={['vertical']}
                columns={[2]}
                limit={5}
                skip={0}
                omitFooter
                callFrom='Event'
              />
              <Divider />
            </Main>
          </Stack>
        )
      })}
      {/* <PreFooter>
            <Pagination {...events.pageInfo}  prefix={'events'} />
          </PreFooter> */}
    </Layout>
  )
}

export default Event
